
// COMPONENTS

// APP
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
	name: "ProjectOverview",
	props: {
		projectId: {
			type: String,
			required: true,
		},
		currentUser: Object,
	},
	setup(props) {
		
		const store = useStore();
		const route = useRoute();
		const router = useRouter();

		let project = ref(null);

		onMounted(async () => {
			//setCurrentPageTitle("Project");
			
			let projectItem = await store.dispatch(Actions.PROJECTS_ITEM_BY_ID, props.projectId );

			if (projectItem != undefined  && projectItem.code == 200) {
				project.value = projectItem.data;
			} else {
				// NO USER FOUND, RETURN BACKWARD
				router.go(-1);
			}
		});

		return {
			project,
		};
	},
});
